import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot, Route, UrlSegment, CanMatch, CanActivate } from '@angular/router';

import { RoutesService } from './routes.service';
import { AuthService } from '../auth/auth.service';
import { MainControllerService } from '../components/main-controller/main-controller.service';
import { appRoles, AuthRolesService } from '../auth/auth-roles.service';
import { RouteData } from './routes.interface';
import { AuthPermissionsService } from '../auth/auth-permissions.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslocoService } from '@jsverse/transloco';

@Injectable({
	providedIn: 'root',
})
export class RouteGuard implements CanMatch, CanActivate {

	private noNavBar = false;

	constructor(
		// private authService: AuthService,
		private router: Router,
		private routesService: RoutesService,
		private authService: AuthService,
		private mainControllerService: MainControllerService,
		private authRolesService: AuthRolesService,
		private authPermissionsService: AuthPermissionsService,
		private matSnackBar: MatSnackBar,
		private translocoService: TranslocoService
	){

		this.lookForNoNavBarURLParameters();

	}

	canMatch(route: Route, urlSegment: UrlSegment[]): boolean {
		return this.canLoad(route, urlSegment);
	}

	canLoad(route: Route, urlSegment: UrlSegment[]): boolean {

		this.authRolesService.setRole(route.data.appRole);

		console.log(
			'routeguard canMatch:',
			this.authRolesService.getRole(),
			[
				route,
				urlSegment
			]
		);

		return this.checkToken(route.data as RouteData, urlSegment);

	}

	canActivate(
		route: ActivatedRouteSnapshot,
		state: RouterStateSnapshot
	): boolean {

		this.authRolesService.setRole(route.data.appRole);
		console.log('routeguard canActivate', route);

		if(this.authRolesService.getRole() !== route.data.appRole){
			this.authService.logout(true);
			return false;
		}

		this.lookForTokenOnURLParameters();

		return this.checkToken(route.data as RouteData);

	}

	private lookForNoNavBarURLParameters(){

		const url = new URL(window.location.href);

		if(url.searchParams.get('navbar') === 'false'){
			this.noNavBar = true;
			sessionStorage.setItem('hideNavBar', 'true');
		}

		if(
			window !== window.parent
			&&
			sessionStorage.getItem('hideNavBar') === 'true'
		){
			this.noNavBar = true;
		}

	}

	private lookForTokenOnURLParameters(){

		const url = new URL(window.location.href);

		if(this.authService.isLogged() && url.searchParams.get('token')){

			url.searchParams.delete('token');

			const queryParams = {};
			url.searchParams.forEach((v, k) => queryParams[k] = v);

			setTimeout(
				() => {
					this.router.navigate(
						[url.pathname],
						{
							replaceUrl: true,
							queryParams
						}
					);
				}
			);

		}

	}

	checkAppRole(): boolean {
		return true;
	}

	private hasPermission(routeData: RouteData){
		if(
			this.authService.isLogged()
			&&
			routeData.permissionKey
			&&
			!this.authPermissionsService.has(routeData.permissionKey)
		){

			this.matSnackBar.open(
				this.translocoService.translate('DefaultHttpRequestMessages.forbidden'),
				'ok',
				{
					// panelClass: 'notFound'
					panelClass: 'forbidden'
				}
			);

			if(!this.noNavBar){
				this.router.navigate(
					['home'],
					{
						replaceUrl: true,
						// queryParamsHandling: 'preserve'
					}
				);
			}

			return false;
		}
		return true;
	}

	// eslint-disable-next-line max-lines-per-function
	private checkToken(routeData: RouteData, urlSegment: UrlSegment[] = []): boolean {

		if(!this.hasPermission(routeData)){
			return false;
		}

		console.log(
			'routeguard checkToken role:',
			[
				this.authService.getTokenInfo(),
				this.authRolesService.getRole(),
				routeData,
				this.router.url
			]
		);

		if (this.authService.isLogged()){
			if(this.noNavBar || routeData.navigationBar === false){
				this.mainControllerService.disableNavigationBar();
			}else if(this.authRolesService.getRole() === appRoles.dashboard){
				this.mainControllerService.enableNavigationBar();
			}
			return true;
		}

		if(routeData.preventRequestLogin && routeData.preventRequestLogin()){
			return true;
		}else{

			this.requestUserLogin(
				routeData.keepAfterLoginRedir
					? urlSegment
					: []
			);

		}

		return false;

	}

	private requestUserLogin(urlSegment: UrlSegment[] = []){

		const pathLogin = [
			this.routesService.getRoute('login').path
		];

		let queryParams: any = {};

		if(urlSegment.length > 0){
			queryParams.redirTo = urlSegment.join(',');
			queryParams.query = encodeURIComponent(window.location.search);
		}else if(this.authService.loginRoutePathParams.appRole){
			pathLogin.push(this.authService.loginRoutePathParams.appRole);
			queryParams = this.authService.loginRoutePathParams.queryParams;
		}

		this.router.navigate(
			pathLogin,
			{
				replaceUrl: true,
				queryParams
				// queryParamsHandling: 'preserve'
			}
		);

	}

}
